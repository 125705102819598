<script>
import {mapGetters} from "vuex";
import html2pdf from 'html2pdf.js';
export default {
    name:"generateInvoice",
    props:["data"],
    data: () => ({
    el: "table",
    items: [
      { description: "Website design", quantity: 1, price: 300 },
      { description: "Website design", quantity: 1, price: 75 },
      { description: "Website design", quantity: 1, price: 10 }
    ]
  }),
  computed: {
      ...mapGetters(['uniqueclient']),
    total() {
      return this.data.Service.reduce(
        (acc, item) => acc + parseFloat(item.total),
        0
      );
    },
    totalDiscount(){
      return this.data.Service.reduce(
        (acc, item) => acc + parseFloat(item.discount),
        0
      );
    }
  },
  mounted(){
         /* eslint-disable no-console */
      this.$store.dispatch('fetchuniqueClient',this.data.clientId)
  },    
  methods: {
     close () {
       var dialog = false;
      this.$emit('clicked',dialog )
    },
    // addRow() {
    //   this.items.push({ description: "", quantity: 1, price: 0 });
    // },
    //to download pdf copy
    generate(){
        // const element =document.getElementById("generate");
        // html2pdf().from(element).save();
        html2pdf(this.$refs.document, {
					margin: 1,
					filename: 'document.pdf',
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { dpi: 192, letterRendering: true },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
				})
    }
  },
  filters: {
    currency(value) {
      return value.toFixed(2);
    }
  }
}
</script>
<template>
<div>
  <v-toolbar flat color="#26BB44" class dark dense>
        <v-toolbar-title>Invoices</v-toolbar-title>
        <v-spacer></v-spacer>
                  <v-btn color="blue" dark @click="generate" >GENERATE</v-btn>
        <v-spacer></v-spacer>
                  <v-btn color="blue" dark @click="close">Close</v-btn>
      </v-toolbar>
    <div class="invoice-box" style="background-color:white" ref ="document">
  <table cellpadding="0" cellspacing="0">
    <tr class="top">
      <td colspan="4">
        <table>
          <tr>
            <td class="title">
              <img src="/old_logo.png" style="width:100%; max-width:300px;" />
            </td>

            <td>
             <b> Invoice #:</b> {{data.invoiceId}}<br><b> Created:</b> {{data.date}}<br><b>RegNo.</b>{{data.regnum}}
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr class="information">
      <td colspan="4">
        <table>
          <tr>
            <td>
                To<br>
              {{uniqueclient.clientName}}<br> {{uniqueclient.clientAddress}}<br>{{uniqueclient.city}}
            </td>

            <td>
              Alco Brake LLC
                              <br />1421 Champion Drive
                              <br />Ste. 352, Carrollton
                              <br />75006
                              <br />Phone: +1 844 425 2627
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- <tr class="heading">
      <td colspan="3">Payment Method</td>
      <td>Check #</td>
    </tr>

    <tr class="details">
      <td colspan="3">Check</td>
      <td>1000</td>
    </tr> -->

    <tr class="heading">
      <td>Service</td>
      <td>Amount</td>
      <td>Description</td>
      <td>Total</td>
    </tr>

    <tr class="item" v-for="item in data.Service" :key="item.price">
      <td><input v-model="item.service"></td>
      <td>$<input type="number" v-model="item.actualamount"></td>
      <td><input type="text" v-model="item.description"></td>
      <td><input  type="number" v-model="item.total"></td>
      
      
    </tr>

    <!-- <tr>
      <td colspan="4">
        <button class="btn-add-row" @click="addRow">Add row</button>
      </td>
    </tr> -->

    <tr class="total">
      <td colspan="3"></td>
      <td>Grand Total: ${{ total | currency }}</td>
    </tr>
    <tr class="total">
      <td colspan="3"></td>
      <td>Discount: ${{ totalDiscount}}</td>
      </tr>
      <tr class="total">
      <td colspan="3"></td>
      <td>Paid Amount: ${{ data.amountpaid}}</td>
      </tr>
      <tr class="total">
      <td colspan="3"></td>
      <td>Due Amount: ${{ data.prevbalance}}</td>
    </tr>
  </table>
  </div>
  <!-- <v-btn @click="generate">Generate</v-btn> -->
  <!-- <v-spacer></v-spacer>
  <v-btn @click="close">Close</v-btn> -->
</div>
</template>
<style scoped>
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

</style>